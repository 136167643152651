<template>
	<div id="atendimento-screen" ref="atendimento-screen">
		<b-spinner v-if="!atendimentos || !config" id="loading-spinner" />
		<div v-if="config && atendimentos"
			 id="protocolos"
			 :class="!search || !search.trim() ? '' : 'on-search'">
			<div id="protocolos-search" class="atendimento-box">
				<div id="protocolos-search-input-box" :class="`${isSearching ? 'searching' : ''}`">
					<input type="text"
						   ref="search-input"
						   placeholder="Buscar atendimento"
						   v-model="search"
						   @focus="isSearching = true"
						   @blur="isSearching = false" />
					<i v-if="!search || ! search.trim()" class="fas fa-search" @click="$refs['search-input'].focus()"></i>
					<i v-else class="fas fa-times" @click="search = null"></i>
				</div>
				<div id="protocolos-search-options">
					<i title="Opções do atendimento"
					   @click.stop="toggleOptionsList('menu1')"
					   class="fas fa-ellipsis-v"></i>
					<ul v-if="optionsListControl.menu1" class="options-list">
						<li :title="`${filaAtiva ? 'Deixar de receber' : 'Liberar o recebimento de'} novos atendimentos`"
							@click="toggleFilaAtiva"
							class="cursor-pointer">
							<span v-if="filaAtiva">Paralisar</span>
							<span v-else>Liberar</span>
							fila de atendimento
						</li>
						<li v-if="config.AtendimentoAtivo && setoresECanais.find(item => item.CanalTipo == 3)?.Habilitado"
							v-b-modal.criar-atendimento
							@click="modalData['criar-atendimento'].canalId = null; modalData['criar-atendimento'].setorId = null">
							Criar atendimento
						</li>
						<li v-if="config.HabilitarEscolhaDeCanal && setoresECanais && setoresECanais.length > 0">
							<div class="align-v-center align-h-between">
								Habilitar/Desabilitar Canais
								<i class="fas fa-angle-right ml-2"></i>
							</div>
							<ul class="options-list" @click.stop>
								<li v-for="item in setoresECanais.filter(item => item.CanalTipo != 3)">
									<label class="m-0 align-v-center sd-checkbox">
										<input type="checkbox"
											   class="mr-2"
											   v-model="item.Habilitado"
											   @change="toggleDisponibilidadeDoCanal(item, item.Habilitado)" />
										<b>{{ item.Canal }}</b>
										<i class="ml-2">{{ item.Setor }}</i>
									</label>
								</li>
							</ul>
						</li>
					</ul>
				</div>
			</div>
			<div id="protocolos-queue-filter" class="atendimento-box">
				<b-container @click.stop="toggleOptionsList('selecaoSetor')"
							 class="protocolos-filter-box font-weight-bold py-2">
					<b-row>
						<b-col class="text-left text-nowrap">
							{{setorSelecionado?.Nome ?? "Todas as filas"}}
						</b-col>
						<b-col class="text-right text-nowrap">
							{{(setorSelecionado ? atendimentosAtivos.filter(a => a.SetorId == setorSelecionado.Id) : atendimentosAtivos).length}} atendimentos
							<i class="fas fa-angle-down ml-2"></i>
						</b-col>
					</b-row>
				</b-container>
				<ul v-if="optionsListControl.selecaoSetor" class="options-list">
					<li @click="setorSelecionado = null" :class="!setorSelecionado ? 'active' : ''">
						<div class="float-left text-truncate" title="Todas as filas">
							Todas as filas
						</div>
						<div class="float-right">
							{{atendimentosAtivos.length}} atendimentos
						</div>
					</li>
					<li v-for="setor in setores"
						:key="setor.Id"
						@click="setorSelecionado = setor"
						:class="setorSelecionado == setor ? 'active' : ''">
						<div class="float-left text-truncate" :title="setor.Nome">
							{{setor.Nome}}
						</div>
						<div class="float-right">
							{{atendimentosAtivos.filter(a => a.SetorId == setor.Id).length}} atendimentos
						</div>
					</li>
				</ul>
			</div>
			<div id="protocolos-list" class="atendimento-box">
				<div class="protocolos-filter-box list-item">
					<b-container @click.stop="toggleOptionsList('selecaoFiltro')" class="py-2">
						<b-row>
							<b-col class="text-left text-truncate">
								{{filtroSelecionado?.nome ?? "Todos os atendimentos"}}
							</b-col>
							<b-col class="text-right text-nowrap">
								{{(filtroSelecionado ? filtroSelecionado.getLista().filter(filtroSelecionado.seletor) : atendimentosAtivos).filter(atendimento => setorSelecionado ? atendimento.SetorId == setorSelecionado.Id : true).length}}
								atendimentos
								<i class="fas fa-angle-down ml-2"></i>
							</b-col>
						</b-row>
					</b-container>
					<ul v-if="optionsListControl.selecaoFiltro" class="options-list">
						<li @click="filtroSelecionado = null" :class="!filtroSelecionado ? 'active' : ''">
							<div class="float-left text-truncate" title="Todos os atendimentos">
								Todos os atendimentos
							</div>
							<div class="float-right">
								{{atendimentosAtivos.filter(atendimento => setorSelecionado ? atendimento.SetorId == setorSelecionado.Id : true).length}} atendimentos
							</div>
						</li>
						<li v-for="filtro in Object.keys(filtros)"
							:key="filtro"
							@click="filtroSelecionado = filtros[filtro]"
							:class="filtroSelecionado == filtros[filtro] ? 'active' : ''">
							<div class="float-left text-truncate" :title="filtros[filtro].nome">
								{{filtros[filtro].nome}}
							</div>
							<div class="float-right">
								{{filtros[filtro].getLista().filter(filtros[filtro].seletor).filter(atendimento => setorSelecionado ? atendimento.SetorId == setorSelecionado.Id : true).length}}
								atendimentos
							</div>
						</li>
					</ul>
				</div>
				<div class="list-item py-2">
					<div class="text-center">
						<b :class="`${quantidadeAtendimentosSimultaneosExcedido ? 'text-danger' : ''}`">
							{{atendimentosAtivos.length}}
						</b> atendimentos ativos
						<span v-if="quantidadeAtendimentosNaFila">
							| <b>{{quantidadeAtendimentosNaFila}}</b> pendentes
						</span>
						<span v-if="quantidadeAtendimentosSimultaneosExcedido"
							  class="atendimentos-simultaneos-limit-alert ml-4"
							  :title="`O limite de atendimentos ativos simultâneos foi atingido. Limite atual: ${config?.QuantidadeAtendimentosSimultaneos}`">
							<i class="fas fa-exclamation mr-1"></i>
							Limite atingido
						</span>
					</div>
				</div>
				<div v-if="!filaAtiva" id="aviso-fila-inativa">
					<i class="fas fa-ban flip-h mr-2"></i>
					Fila de atendimento paralisada.
					<br />
					<span class="span-link" @click="toggleFilaAtiva">Liberar fila de atendimento agora</span>
				</div>
				<div v-if="controleTelefonia" id="controle-telefonia" :class="controleTelefonia?.type">
					<div v-if="controleTelefonia?.type == 'ChamadaSolicitada'">
						<b-container>
							<b-row align-h="between">
								<b-col class="text-nowrap text-left">
									<i class="fas fa-user-alt mr-2"></i>
									{{maskPhone(controleTelefonia.telefone)}}
								</b-col>
								<b-col v-if="controleTelefonia.nome?.trim()"
									   class="text-truncate"
									   :title="controleTelefonia.nome">
									{{controleTelefonia.nome}}
								</b-col>
								<b-col cols="12" md="auto">
									<i class="fas fa-phone flip-h cursor-pointer mr-3"
									   @click="controleTelefonia.answerCall ? controleTelefonia.answerCall() : null"
									   title="Atender"></i>
									<i class="fas fa-times fa-lg cursor-pointer"
									   @click="controleTelefonia.rejectCall ? controleTelefonia.rejectCall() : null"
									   title="Rejeitar"></i>
								</b-col>
							</b-row>
						</b-container>
					</div>
					<div v-if="controleTelefonia?.type == 'ChamadaFinalizada'">
						<i class="fas fa-phone-slash flip-h mr-2"></i>
						Chamada encerrada.
					</div>
					<div v-if="controleTelefonia?.type == 'ChamadaIniciada'">
						<b-container>
							<b-row align-h="between">
								<b-col class="text-truncate text-left">
									<i class="fas fa-phone flip-h mr-2"></i>
									<b class="mr-2">#{{controleTelefonia.protocolo}}</b>
									<span :title="controleTelefonia.nome?.trim() ?? maskPhone(controleTelefonia.telefone)">
										{{controleTelefonia.nome?.trim() ?? maskPhone(controleTelefonia.telefone)}}
									</span>
								</b-col>
								<b-col cols="12" md="auto">
									<span v-if="controleTelefonia.timer != null">{{controleTelefonia.timer}}</span>
									<i v-else class="fas fa-spinner fa-spin"></i>
									<i v-if="controleTelefonia.toggleMic"
									   :class="`fas fa-microphone${controleTelefonia.micEnabled ? '' : '-slash'} fa-fw cursor-pointer ml-2`"
									   @click="controleTelefonia.toggleMic"
									   :title="`${controleTelefonia.micEnabled ? 'Silenciar' : 'Habilitar'} microfone`"></i>
									<i v-if="controleTelefonia.hangupCall"
									   class="fas fa-times fa-lg cursor-pointer ml-2"
									   @click="controleTelefonia.hangupCall"
									   title="Desligar"></i>
								</b-col>
							</b-row>
						</b-container>
					</div>
					<div v-if="controleTelefonia?.type == 'ChamadaRejeitada'">
						<i class="fas fa-ban mr-2"></i>
						Chamada rejeitada.
					</div>
					<div v-if="controleTelefonia?.type == 'ChamadaInvalida'">
						<i class="fas fa-ban mr-2"></i>
						Acesso à linha telefônica negado.
					</div>
					<div v-if="controleTelefonia?.type == 'ChamadaAtivaSolicitada'">
						<b-container>
							<b-row align-h="between">
								<b-col class="text-truncate text-left">
									<i class="fas fa-phone flip-h mr-2"></i>
									<b class="mr-2">#{{controleTelefonia.protocolo}}</b>
									<span :title="controleTelefonia.nome?.trim() ?? maskPhone(controleTelefonia.telefone)">
										Ligando para
										{{controleTelefonia.nome?.trim() ?? maskPhone(controleTelefonia.telefone)}}
									</span>
								</b-col>
								<b-col cols="12" md="auto">
									<i class="fas fa-spinner fa-spin"></i>
									<i class="fas fa-times fa-lg cursor-pointer ml-2"
									   @click="controleTelefonia.cancelCall ? controleTelefonia.cancelCall() : null"
									   title="Cancelar"></i>
								</b-col>
							</b-row>
						</b-container>
					</div>
				</div>
				<div v-if="chatHubConnectionStatus" id="connection-status" :class="chatHubConnectionStatus">
					<div v-if="chatHubConnectionStatus == 'connecting'">
						<b-spinner small class="mr-2" />
						Conectando ao servidor...
					</div>
					<div v-if="chatHubConnectionStatus == 'connected'">
						<i class="fas fa-wifi mr-2"></i>
						Conexão estabelecida.
					</div>
					<div v-if="chatHubConnectionStatus == 'reconnecting'">
						<b-spinner small class="mr-2" />
						Conexão perdida. Reconectando...
					</div>
					<div v-if="chatHubConnectionStatus == 'reconnected'">
						<i class="fas fa-wifi mr-2"></i>
						Conexão reestabelecida.
					</div>
					<div v-if="chatHubConnectionStatus == 'disconnected'">
						<i class="fas fa-times mr-2"></i>
						Não foi possível se conectar ao servidor.
						<br />
						<span class="span-link" @click="reload">Recarregar atendimentos</span>
					</div>
					<div v-if="chatHubConnectionStatus == 'offline'">
						<span class="fa-stack fa">
							<i class="fas fa-times fa-xs fa-stack-1x"></i>
							<i class="fas fa-wifi"></i>
						</span>
						Você está offline.
					</div>
				</div>

				<div v-if="filtroSelecionado && atendimentosFiltrados.length == 0" class="text-center p-2 mt-2">
					Nenhum atendimento ativo para este filtro.
				</div>
				<div v-else-if="(!atendimentosAtivos || atendimentosAtivos.length == 0) && !filtroSelecionado"
					 class="text-center p-2 mt-2">
					<i class="fas fa-mug-hot mr-2"></i>
					Nenhum atendimento ativo ou pendente.
				</div>
				<div v-else id="protocolos-list-container" class="app-scroll-custom">
					<b-container v-for="atendimento in atendimentosFiltrados"
								 :key="`atendimento-${atendimento.Protocolo}`"
								 :class="`list-item${atendimentoSelecionado == atendimento ? ' active' : ''}${atendimento.EmProcessamento ? ' closing' : ''}`"
								 :style="{ 'border-color': atendimento.Canal.Color }"
								 @click="selecionarAtendimento(atendimento)">
						<b-row align-v="center">
							<b-col cols="2">
								<b-spinner v-if="atendimento.EmProcessamento"></b-spinner>
								<i v-else
								   :class="`protocolo-canal ${atendimento.Canal.Icon}`"
								   :style="{ 'font-size': atendimento.Canal.Size ? `${40 * atendimento.Canal.Size}px` : null }"></i>
							</b-col>
							<b-col cols="8">
								<b-row>
									<b-col class="protocolo-numero">#{{atendimento.Protocolo}}</b-col>
								</b-row>
								<b-row>
									<b-col class="protocolo-cliente text-truncate" :title="`${[atendimento.Cliente, atendimento.Canal.Tipo == 'Telefonia' ? maskPhone(atendimento.ClienteTelefone) : ''].join(' ').trim()}`">
										{{atendimento.Cliente}}
										<span v-if="atendimento.Canal.Tipo == 'Telefonia'">
											{{maskPhone(atendimento.ClienteTelefone)}}
										</span>
									</b-col>
								</b-row>
								<b-row>
									<b-col v-if="atendimento.DataAgendamento" class="protocolo-data">
										<i class="fas fa-clock mr-1"></i>
										<b>Agendado para:</b>
										{{atendimento.DataAgendamento.format(moment().format("YYYY-MM-DD") == atendimento.DataAgendamento.format("YYYY-MM-DD") ? "HH:mm" : "DD/MM/YYYY HH:mm")}}
									</b-col>
									<b-col v-else-if="atendimento.DataUltimaMensagem" class="protocolo-data">
										{{atendimento.DataUltimaMensagem.format(moment().format("YYYY-MM-DD") == atendimento.DataUltimaMensagem.format("YYYY-MM-DD") ? "HH:mm" : "DD/MM/YYYY HH:mm")}}
									</b-col>
								</b-row>
							</b-col>
							<b-col cols="2" class="pl-2">
								<div v-if="atendimento.DataAgendamentoExpirado > atendimento.DataUltimaLeitura" class="protocolo-novas_mensagens">
									<i class="fas fa-calendar-alt"></i>
								</div>
								<div v-else-if="atendimento != atendimentoSelecionado && atendimento.NovasMensagens && atendimento.NovasMensagens > 0"
									 class="protocolo-novas_mensagens">
									{{atendimento.NovasMensagens > 99 ? "99+" : atendimento.NovasMensagens}}
								</div>
							</b-col>
						</b-row>
					</b-container>
				</div>
			</div>
		</div>
		<div v-if="atendimentoSelecionado && atendimentoSelecionado.UsuarioId == meuId"
			 id="atendimento"
			 :key="atendimentoSelecionado.Id"
			 :class="windowSizeControl.mode">
			<div id="atendimento-data" :class="isDataOpen ? '' : 'closed'">
				<div class="atendimento-box h-100">
					<b-container id="atendimento-data-header" class="atendimento-box-header">
						<b-row align-v="center">
							<b-col>
								<div id="atendimento-data-selector"
									 @click.stop="toggleOptionsList('selecaoDados')">
									{{dados[dadosSelecionado].titulo}}
									<i class="fas fa-angle-down"></i>
								</div>
								<ul v-if="optionsListControl.selecaoDados" class="options-list">
									<li v-for="item in Object.keys(dados)"
										v-if="dadosSelecionado != item && dados[item].isActive()"
										@click="dadosSelecionado = item">
										{{dados[item].titulo}}
									</li>
								</ul>
							</b-col>
							<div cols="12" md="auto" class="mr-3">
								<i :title="isFullscreen ? 'Sair da tela cheia' : 'Tela cheia'"
								   @click="toggleFullscreen"
								   :class="`fas ${isFullscreen ? 'fa-compress' : 'fa-expand'} screen-action`"></i>
								<i v-if="windowSizeControl.mode == 'slim'"
								   class="fas fa-times screen-action ml-3"
								   @click="isDataOpen = false"></i>
							</div>
						</b-row>
					</b-container>
					<div v-if="dadosSelecionado == 'tabulacoes'" class="atendimento-data-container">
						<div class="atendimento-data-body app-scroll-custom">
							<div v-if="atendimentoSelecionado?.DadosDiscador" class="atendimento-dados-discador app-scroll-custom">
								<div class="font-weight-bold my-2">
									<i class="fas fa-fax mr-1"></i>
									Dados do Discador
								</div>
								<b-table-simple small striped class="mb-0">
									<b-tbody>
										<b-tr v-for="key in Object.keys(atendimentoSelecionado.DadosDiscador)">
											<b-td>{{key.startsWith(":") ? key.slice(1) : key}}</b-td>
											<b-td>{{atendimentoSelecionado.DadosDiscador[key]}}</b-td>
										</b-tr>
									</b-tbody>
								</b-table-simple>
							</div>
							<div v-for="item in tabulacoesVisiveis"
								 :key="`tabulacao-${item.TabulacaoId}`"
								 class="data-field">
								<label :for="`tabulacao-${item.TabulacaoId}`">{{item.Titulo}}</label>
								<input v-if="item.Tipo == 1"
									   type="number"
									   :id="`tabulacao-${item.TabulacaoId}`"
									   v-model="tabulacoes[item.TabulacaoId]" />
								<input v-else-if="item.Tipo == 2"
									   type="datetime-local"
									   :id="`tabulacao-${item.TabulacaoId}`"
									   v-model="tabulacoes[item.TabulacaoId]" />
								<select v-else-if="item.Tipo == 3"
										:id="`tabulacao-${item.TabulacaoId}`"
										v-model="tabulacoes[item.TabulacaoId]">
									<option :value="null"></option>
									<option v-for="item in item.Itens"
											:value="item.ItemId">
										{{item.Titulo}}
									</option>
								</select>
								<select v-else-if="item.Tipo == 4"
										:id="`tabulacao-${item.TabulacaoId}`"
										v-model="tabulacoes[item.TabulacaoId]">
									<option :value="null"></option>
									<option value="1">Sim</option>
									<option value="0">Não</option>
								</select>
								<input v-else
									   type="text"
									   :id="`tabulacao-${item.TabulacaoId}`"
									   v-model="tabulacoes[item.TabulacaoId]" />
							</div>
						</div>
						<b-container class="atendimento-data-footer m-0" fluid>
							<b-row>
								<b-col>
									<b-button variant="success"
											  title="Salvar alterações"
											  @click="salvarTabulacoesAtendimento"
											  class="atendimento-data-action w-100 rounded-0"
											  :disabled="loaders.salvandoTabulacao">
										<span v-if="loaders.salvandoTabulacao">
											<b-spinner small class="mr-2"></b-spinner>
											Processando...
										</span>
										<span v-else>
											Salvar alterações
										</span>
									</b-button>
								</b-col>
							</b-row>
						</b-container>
					</div>
					<div v-if="dadosSelecionado == 'cliente' && (atendimentoSelecionado.ClienteId ? cliente : true)"
						 class="atendimento-data-container">
						<div class="atendimento-data-body app-scroll-custom">
							<div v-for="campo in Object.keys(camposCliente)"
								 :key="`cliente-${campo}`"
								 class="data-field">
								<label :for="`cliente-${campo}`">{{camposCliente[campo]}}</label>
								<input type="text" :id="`cliente-${campo}`" v-model="cliente[campo]" :disabled="loaders.salvandoCliente || loaders.vinculandoCliente" />
							</div>
							<div v-for="(dado, i) in camposComplementares" class="data-field">
								<label>{{dado.NomeCampo}}</label><br />
								<input v-if="dado.TipoCampo == 0" type="text" v-model="dado.Resposta" />
								<select v-else-if="dado.TipoCampo == 3" v-model="dado.RespostaOpcaoId">
									<option v-for="(op, i) in dado.ClientesComplementoOpcao" :value='op.Id'>
										{{op.NomeOpcao}}
									</option>
								</select>
							</div>
						</div>
						<div class="atendimento-data-footer">
							<b-button v-if="cliente.Id == null"
									  variant="secondary"
									  title="Vincular atendimento a um cliente já existente"
									  @click="vincularClienteAtendimento"
									  :disabled="loaders.salvandoCliente || loaders.vinculandoCliente"
									  class="atendimento-data-action w-50 text-truncate rounded-0">
								<span v-if="loaders.vinculandoCliente">
									<b-spinner small class="mr-2"></b-spinner>
									Processando...
								</span>
								<span v-else>Vincular cliente já existente</span>
							</b-button>
							<b-button v-else variant="secondary"
									  title="Remover vínculo entre o atendimento e o cliente"
									  @click="removerVinculoClienteAtendimento"
									  :disabled="loaders.salvandoCliente || loaders.vinculandoCliente"
									  class="atendimento-data-action w-50 text-truncate rounded-0">
								<span v-if="loaders.vinculandoCliente">
									<b-spinner small class="mr-2"></b-spinner>
									Processando...
								</span>
								<span v-else>Remover vínculo</span>
							</b-button>
							<b-button variant="success"
									  :title="cliente.Id == null ? 'Cadastrar novo cliente' : 'Salvar alterações nos dados do cliente'"
									  @click="salvarClienteAtendimento"
									  :disabled="loaders.salvandoCliente || loaders.vinculandoCliente"
									  class="atendimento-data-action w-50 text-truncate rounded-0">
								<span v-if="loaders.salvandoCliente">
									<b-spinner small class="mr-2"></b-spinner>
									Processando...
								</span>
								<span v-else>{{cliente.Id == null ? "Cadastrar cliente" : "Salvar alterações"}}</span>
							</b-button>
						</div>
					</div>
					<div v-else-if="dadosSelecionado == 'cliente' && atendimentoSelecionado.ClienteId && !cliente">
						<center>
							<b-spinner small class="text-cliente mt-3"></b-spinner>
						</center>
					</div>
					<div v-if="dadosSelecionado == 'historicoAtendimentos'" class="atendimento-data-container">
						<div v-if="historicoAtendimentos && historicoAtendimentos.length > 0"
							 class="atendimento-data-body full-height app-scroll-custom">
							<b-container v-for="atendimento in historicoAtendimentos"
										 :key="`historico_atendimento-${atendimento.Id}`"
										 @click="abrirModalConversaPadrao(atendimento)"
										 class="atendimento-historico">
								<b-row align-v="center">
									<b-col cols="12" md="auto" class="ml-1 pr-0">
										<i class="fas fa-info-circle mr-1"></i>
									</b-col>
									<b-col class="text-nowrap">
										<div>
											<b class="atendimento-historico-protocolo">#{{atendimento.Protocolo}}</b>
											<span class="atendimento-historico-small ml-2">({{atendimento.Canal}})</span>
										</div>
										<div>
											<b>Atendente:</b>
											{{atendimento.Atendente}}
										</div>
										<div v-if="atendimento.DataUltimaMensagem" class="atendimento-historico-small">
											{{moment(atendimento.DataUltimaMensagem).format("DD/MM/YYYY HH:mm")}}
											- {{atendimento.Status}}
										</div>
									</b-col>
								</b-row>
							</b-container>
						</div>
						<center v-else-if="loaders.consultandoHistoricoAtendimentos" class="my-3">
							<b-spinner small class="text-cliente"></b-spinner>
						</center>
						<center v-else class="text-secondary my-3">
							Não há nenhum histórico disponível para consulta.
						</center>
					</div>
				</div>
			</div>
			<div id="atendimento-chat">
				<div class="atendimento-box h-100">
					<b-container class="atendimento-box-header" fluid>
						<b-row id="atendimento-box-header-row1" align-v="center">
							<b-col id="atendimento-box-header-col1" class="pr-0">
								<i id="atendimento-chat-canal-icon"
								   :class="atendimentoSelecionado?.Canal.Icon"
								   :style="{ 'color': atendimentoSelecionado?.Canal.Color, 'font-size': atendimentoSelecionado?.Canal.Size ? `${30 * atendimentoSelecionado?.Canal.Size}px` : null }"></i>
							</b-col>
							<b-col id="atendimento-box-header-col2">
								<div id="atendimento-chat-title"
									 class="text-truncate float-left mr-3"
									 :style="{ 'color': atendimentoSelecionado?.Canal.Color }">
									#{{atendimentoSelecionado?.Protocolo}}
									<span v-if="atendimentoSelecionado?.Cliente?.trim()"
										  :title="atendimentoSelecionado?.Cliente">
										- {{atendimentoSelecionado?.Cliente}}
									</span>
								</div>
								<div class="text-nowrap float-left">
									Iniciado em
									<span v-if="atendimentoSelecionado?.DataPrimeiraMensagem">
										{{atendimentoSelecionado?.DataPrimeiraMensagem.format("DD/MM/YYYY HH:mm")}}
									</span>
									| Canal:
									<div id="atendimento-chat-canal-nome"
										 class="text-truncate"
										 :title="atendimentoSelecionado?.Canal.Nome">
										{{atendimentoSelecionado?.Canal.Nome}}
									</div>
									<i v-if="!atendimentoSelecionado.Canal.Tipo"
									   id="atendimento-chat-canal-trocar"
									   title="Alterar canal"
									   v-b-modal.alterar-canal
									   @click="modalData['alterar-canal'].canalId = atendimentoSelecionado.Canal.Id; modalData['alterar-canal'].setorId = atendimentoSelecionado.SetorId"
									   class="fas fa-exchange-alt ml-2"></i>
								</div>
							</b-col>
							<b-col id="atendimento-box-header-col3" class="pr-0">
								<i id="botaoInfoIcon" class="fas fa-info-circle fa-fw conversa_funil" @click="abrirModalConversaPadrao(atendimentoSelecionado)"></i>
								<div id="atendimento-acoes-opener">
									<i @click.stop="toggleOptionsList('opcoesAtendimento')"
									   title="Ações do atendimento"
									   :class="`fas ${optionsListControl.opcoesAtendimento ? 'fa-minus' : 'fa-plus'}`"></i>
									<ul v-if="optionsListControl.opcoesAtendimento" class="options-list">
										<li v-if="config.ChamadaAtiva && cliente?.Id && atendimentoSelecionado.Canal?.Tipo == 'Telefonia'" @click="makeCall">
											Ligar para o cliente...
										</li>
										<li @click="ocultarMensagensInternas = !ocultarMensagensInternas">
											{{ocultarMensagensInternas ? "Exibir" : "Ocultar"}}
											mensagens internas
										</li>
										<li v-if="templatesMensagem && templatesMensagem.length > 0"
											@click="$refs['chat'].identificarTemplateMensagem($event)"
											class="mb-2">
											Ver templates
										</li>
										<li v-if="atendimentoSelecionado.Canal.Enum == 15"
											@click="$refs['chat'].consultarTemplatesWppBusiness(atendimentoSelecionado?.SetorId)"
											class="templates-wpp-busines-selector mb-2">
											<i class="fab fa-whatsapp mr-1"></i>
											Templates Wpp Business...
										</li>
										<li v-if="atendimentoSelecionado.Inicializacao == 2 && !cliente.DescadastradoDeCampanhas"
											@click="descadastrarDeCampanhas"
											class="mb-2">
											Descadastrar de campanhas
										</li>
										<li @click="iniciarTransferenciaDoAtendimento">Transferir</li>
										<li v-b-modal.agendar-atendimento @click="modalData['agendar-atendimento'].dataAgendamento = atendimentoSelecionado.DataAgendamento ? atendimentoSelecionado.DataAgendamento.format('YYYY-MM-DD HH:mm') : null">
											{{atendimentoSelecionado.DataAgendamento ? "Reagendar" : "Agendar"}}
										</li>
										<li v-if="atendimentoSelecionado.Status != 6"
											@click="suspenderAtendimento">Suspender</li>
										<li v-if="atendimentoSelecionado.Status != 2"
											@click="finalizarAtendimento" class="mt-2">Finalizar</li>
									</ul>
								</div>
								<i v-if="!isDataOpen"
								   :title="isFullscreen ? 'Sair da tela cheia' : 'Tela cheia'"
								   @click="toggleFullscreen"
								   :class="`fas ${isFullscreen ? 'fa-compress' : 'fa-expand'} screen-action`"></i>
								<i title="Mostrar tabulação e opções avançadas"
								   @click="isDataOpen = !isDataOpen"
								   :class="`fas fa-angle-double-${isDataOpen ? 'right' : 'left'} screen-action`"></i>
							</b-col>
						</b-row>
					</b-container>
					<Chat ref="chat"
						  :atendimentoId="atendimentoSelecionado?.Id"
						  :ocultarMensagensInternas="ocultarMensagensInternas"
						  :notificacaoLeitura="atendimentoSelecionado.UsuarioId == meuId"
						  :editor="atendimentoSelecionado.Status != 2 ? atendimentoSelecionado.Editor : null"
						  :templates="templatesMensagem"
						  :canal="atendimentoSelecionado.Canal"
						  :tipoCanal="atendimentoSelecionado.Canal.Tipo"
						  :referencias="[`#${atendimentoSelecionado?.Protocolo}`,...Object.keys(cliente?._data ?? {}).filter(key => !['Id'].includes(key) && cliente[key] != null && typeof cliente[key] === 'string' && cliente[key].trim().length > 0).map(key => cliente[key])]"
						  class="chat-container" />
				</div>
			</div>
		</div>
		<b-modal v-if="config && config.AtendimentoAtivo"
				 :static="true"
				 id="criar-atendimento"
				 title="Criar atendimento ativo">
			<div class="data-field">
				<label for="criar-atendimento-setor">Setor</label>
				<select id="criar-atendimento-setor" v-model="modalData['criar-atendimento'].setorId" @change="modalData['criar-atendimento'].canalId = null">
					<option :value="null"></option>
					<option v-for="setor in setores" :value="setor.Id">{{setor.Nome}}</option>
				</select>
			</div>
			<div class="data-field">
				<label for="criar-atendimento-canal">Canal</label>
				<select id="criar-atendimento-canal" v-model="modalData['criar-atendimento'].canalId" :disabled="!modalData['criar-atendimento'].setorId">
					<option :value="null"></option>
					<option v-for="item in (setoresECanais ?? []).filter(item => item.SetorId == modalData['criar-atendimento'].setorId)"
							:value="item.CanalId">
						{{item.Canal}}
					</option>
				</select>
			</div>
			<template #modal-footer="{ cancel }">
				<b-button variant="light"
						  @click="cancel()"
						  class="rounded-0"
						  :disabled="loaders.criandoAtendimento">Cancelar</b-button>
				<b-button variant="success"
						  @click="criarAtendimento"
						  class="rounded-0"
						  :disabled="!modalData['criar-atendimento'].setorId || !modalData['criar-atendimento'].canalId || loaders.criandoAtendimento">
					<span v-if="loaders.criandoAtendimento">
						<b-spinner small class="mr-1"></b-spinner>
						Processando...
					</span>
					<span v-else>Confirmar</span>
				</b-button>
			</template>
		</b-modal>
		<b-modal v-if="atendimentoSelecionado"
				 :static="true"
				 id="alterar-canal"
				 title="Alterar canal do atendimento">
			<div class="data-field">
				<label for="alterar-canal-canal">Canal</label>
				<select id="alterar-canal-canal" v-model="modalData['alterar-canal'].canalId"
						@change="modalData['alterar-canal'].setorId = null">
					<option :value="null"></option>
					<option v-for="canal in canais" :value="canal.Id">{{canal.Nome}}</option>
				</select>
			</div>
			<div class="data-field">
				<label for="alterar-canal-setor">Setor</label>
				<select id="alterar-canal-setor"
						v-model="modalData['alterar-canal'].setorId"
						:disabled="!modalData['alterar-canal'].canalId">
					<option :value="null"></option>
					<option v-for="item in (setoresECanais ?? []).filter(item => item.CanalId == modalData['alterar-canal'].canalId)"
							:value="item.SetorId">
						{{item.Setor}}
					</option>
				</select>
			</div>
			<template #modal-footer="{ cancel }">
				<b-button variant="light"
						  @click="cancel()"
						  class="rounded-0"
						  :disabled="loaders.alterandoCanal">Cancelar</b-button>
				<b-button variant="success"
						  @click="alterarCanalAtendimento"
						  class="rounded-0"
						  :disabled="!modalData['alterar-canal'].setorId || !modalData['alterar-canal'].canalId || loaders.alterandoCanal">
					<span v-if="loaders.alterandoCanal">
						<b-spinner small class="mr-1"></b-spinner>
						Processando...
					</span>
					<span v-else>Confirmar</span>
				</b-button>
			</template>
		</b-modal>
		<b-modal v-if="atendimentoSelecionado"
				 :static="true"
				 id="agendar-atendimento"
				 :title="`${atendimentoSelecionado.DataAgendamento ? 'Reagendar' : 'Agendar'} atendimento #${atendimentoSelecionado.Protocolo}`">
			<div class="data-field">
				<label for="agendar-atendimento-data-agendamento">Data de Agendamento</label>
				<input type="datetime-local"
					   id="agendar-atendimento-data-agendamento"
					   v-model="modalData['agendar-atendimento'].dataAgendamento" />
			</div>
			<div v-if="atendimentos && atendimentos.filter(atendimento => atendimento.DataAgendamento && atendimento != atendimentoSelecionado).length > 0"
				 id="agendar-atendimento-agendados"
				 class="data-field">
				<label>{{atendimentoSelecionado.DataAgendamento ? "Outros agendamentos" : "Agendamentos"}} ativos</label>
				<b-table :fields="['#', 'Protocolo', 'Cliente', 'DataAgendamento']"
						 :items="atendimentos.filter(atendimento => atendimento.DataAgendamento && atendimento != atendimentoSelecionado).sort((a, b) => a.DataAgendamento < b.DataAgendamento ? -1 : 0).map(item => ({ Canal: item.Canal, Protocolo: item.Protocolo, Cliente: item.Cliente, DataAgendamento: item.DataAgendamento.format('DD/MM/YYYY HH:mm') }))"
						 striped
						 sticky-header
						 class="mt-2 app-scroll-custom">
					<template #cell(#)="data">
						<i :class="data.item.Canal.Icon"></i>
					</template>
				</b-table>
			</div>
			<template #modal-footer="{ cancel }">
				<b-button variant="light"
						  @click="cancel()"
						  class="rounded-0"
						  :disabled="loaders.agendandoAtendimento">Cancelar</b-button>
				<b-button variant="success"
						  @click="agendarAtendimento"
						  class="rounded-0"
						  :disabled="!modalData['agendar-atendimento'].dataAgendamento || loaders.agendandoAtendimento">
					<span v-if="loaders.agendandoAtendimento">
						<b-spinner small class="mr-1"></b-spinner>
						Processando...
					</span>
					<span v-else>Confirmar</span>
				</b-button>
			</template>
		</b-modal>
		<b-modal v-if="atendimentoSelecionado"
				 :static="true"
				 id="transferir-atendimento"
				 title="Transferir atendimento">
			<center v-if="loaders.consultandoSetoresEUsuarios" class="my-3">
				<b-spinner class="text-cliente"></b-spinner>
			</center>
			<div v-else>
				<div class="data-field">
					<label for="transferir-atendimento-setor">Setor</label>
					<select id="transferir-atendimento-setor"
							v-model="modalData['transferir-atendimento'].setor"
							@change="modalData['transferir-atendimento'].usuario = null">
						<option v-for="setor in setoresEUsuarios" :value="setor">
							{{setor.Nome}} ({{setor.Usuarios.filter(usuario => usuario.ConectadoNoChat).length}} online)
						</option>
					</select>
				</div>
				<div v-if="config.TransferenciaNominal" class="data-field">
					<label for="transferir-atendimento-usuario">Atendente</label>
					<select id="transferir-atendimento-usuario"
							v-model="modalData['transferir-atendimento'].usuario">
						<option :value="null">Qualquer atendente do setor</option>
						<option v-for="usuario in modalData['transferir-atendimento'].setor?.Usuarios ?? []"
								:value="usuario">
							{{usuario.Nome}} ({{usuario.ConectadoNoChat ? "online" : "offline"}})
						</option>
					</select>
				</div>
			</div>
			<template #modal-footer="{ cancel }">
				<b-button variant="light"
						  @click="cancel()"
						  class="rounded-0"
						  :disabled="loaders.transferindoAtendimento">Cancelar</b-button>
				<b-button variant="success"
						  @click="transferirAtendimento"
						  class="rounded-0"
						  :disabled="!modalData['transferir-atendimento'].setor || loaders.transferindoAtendimento">
					<span v-if="loaders.transferindoAtendimento">
						<b-spinner small class="mr-1"></b-spinner>
						Processando...
					</span>
					<span v-else>Confirmar</span>
				</b-button>
			</template>
		</b-modal>
		<ModalSelecaoCliente v-if="atendimentoSelecionado && atendimentoSelecionado?.ClienteId == null" ref="selecao-cliente" />
		<ModalConversaPadrao ref="conversa-padrao" :dadosConversa="modalData['conversa-padrao']" />
	</div>
</template>

<script>
	import moment from "moment";
	import axios from "axios";
	import omniHost from "@/config/host";
	import { mapGetters } from "vuex";

	import Atendimento from "@/assets/js/models/atendimento";
	import { Cliente } from "@/assets/js/models/atendimento";

	import ChatHub from "@/assets/js/hubs/ChatHub";

	import Chat from "@/components/atendimento/Chat";
	import ModalSelecaoCliente from "@/components/modals/ModalSelecaoCliente";
	import ModalConversaPadrao from "@/components/consultas/ModalCoversaPadrao";

	export default {
		components: {
			Chat,
			ModalSelecaoCliente,
			ModalConversaPadrao
		},
		data() {
			return {
				config: null,
				filaAtiva: true,
				isSearching: false,
				isFullscreen: false,
				isDataOpen: true,
				atendimentos: null,
				atendimentoSelecionado: null,
				quantidadeAtendimentosNaFila: 0,
				setoresECanais: null,
				camposComplementares: [],
				setoresEUsuarios: null,
				setorSelecionado: null,
				filtros: {
					novosProtocolos: {
						nome: "Novos Protocolos",
						seletor: atendimento => !atendimento.DataInicioInteracao,
						getLista: () => this.atendimentosAtivos
					},
					emAndamento: {
						nome: "Em andamento",
						seletor: atendimento => atendimento.DataInicioInteracao,
						getLista: () => this.atendimentosAtivos
					},
					novasMensagens: {
						nome: "Novas Mensagens",
						seletor: atendimento => atendimento.NovasMensagens > 0 && atendimento.OrigemUltimaMensagem == "cliente",
						getLista: () => this.atendimentosAtivos
					},
					aguardandoResposta: {
						nome: "Aguardando resposta",
						seletor: atendimento => atendimento.OrigemUltimaMensagem == "cliente",
						getLista: () => this.atendimentosAtivos
					},
					respondidos: {
						nome: "Respondidos",
						seletor: atendimento => atendimento.OrigemUltimaMensagem == "atendente",
						getLista: () => this.atendimentosAtivos
					},
					lidosPeloCliente: {
						nome: "Cliente Leu e Não Respondeu",
						seletor: atendimento => atendimento.StatusUltimaMensagem == "Lido" && atendimento.OrigemUltimaMensagem == "atendente",
						getLista: () => this.atendimentosAtivos
					},
					agendados: {
						nome: "Agendados",
						seletor: atendimento => atendimento.DataAgendamento != null,
						getLista: () => this.atendimentos
					},
					suspensos: {
						nome: "Suspensos",
						seletor: atendimento => atendimento.Status == 6,
						getLista: () => this.atendimentos
					}
				},
				filtroSelecionado: null,
				dados: {
					tabulacoes: {
						titulo: "Tabulações",
						isActive: () => this.tabulacoesBase && this.tabulacoesBase.length > 0
					},
					cliente: {
						titulo: "Cliente",
						isActive: () => true
					},
					historicoAtendimentos: {
						titulo: "Histórico de Atendimentos",
						isActive: () => true
					}
				},
				dadosSelecionado: "cliente",
				camposCliente: {
					RazaoSocial: "Nome do Cliente",
					CnpjCpf: "CPF/CNPJ",
					Telefone: "Telefone",
					Email: "E-mail",
					OrigemContato: "Origem",
					Cep: "CEP",
					Complemento: "Endereço complemento",
					Endereco: "Endereço",
					Municipio: "Cidade",
					UF: "UF"
				},
				clienteId: null,
				cliente: new Cliente(),
				historicoAtendimentos: null,
				tabulacoesBase: null,
				tabulacoes: null,
				buscaComp: [],
				tabulacoesSalvas: null,
				templatesMensagem: null,
				search: null,
				windowSizeControl: {
					size: null,
					minSize: 1400,
					mode: "normal"
				},
				optionsListControl: {
					menu1: false,
					selecaoSetor: false,
					selecaoFiltro: false,
					opcoesAtendimento: false,
					selecaoDados: false
				},
				ocultarMensagensInternas: false,
				modalData: {
					"criar-atendimento": {
						setorId: null,
						canalId: null
					},
					"alterar-canal": {
						canalId: null,
						setorId: null
					},
					"agendar-atendimento": {
						dataAgendamento: null,
					},
					"transferir-atendimento": {
						setor: null,
						usuario: null
					},
					"conversa-padrao": {
						Id: null,
						Protocolo: null,
						ClienteId: null
					}
				},
				loaders: {
					criandoAtendimento: false,
					salvandoTabulacao: false,
					alterandoCanal: false,
					agendandoAtendimento: false,
					enviandoMensagem: false,
					consultandoSetoresEUsuarios: false,
					transferindoAtendimento: false,
					salvandoCliente: false,
					vinculandoCliente: false,
					consultandoHistoricoAtendimentos: false
				},
				chatHubConnection: null,
				chatHubConnectionStatus: null,
				chatHubConnectionStatusHideTimeout: null,
				controleTelefonia: null,
				controleTelefoniaHideTimeout: null
			};
		},
		computed: {
			...mapGetters({
				dadosUsuarioAutenticado: "authentication/authDadosUsuario",
			}),
			meuId() {
				return this.dadosUsuarioAutenticado.usuarioId;
			},
			setores() {
				if (!this.setoresECanais) return null;
				return Array.from(new Set(this.setoresECanais.map(item => item.SetorId))).map(item => ({
					Id: item,
					Nome: this.setoresECanais.find(setor => setor.SetorId == item).Setor
				})).sort((a, b) => a.Nome > b.Nome ? 0 : -1);
			},
			canais() {
				if (!this.setoresECanais) return null;
				return Array.from(new Set(this.setoresECanais.map(item => item.CanalId))).map(item => ({
					Id: item,
					Nome: this.setoresECanais.find(canal => canal.CanalId == item).Canal
				})).sort((a, b) => a.Nome > b.Nome ? 0 : -1);
			},
			atendimentosAtivos() {
				if (!this.atendimentos) return null;
				return this.atendimentos.filter(atendimento => ![1, 2, 6, 7].includes(atendimento.Status));
			},
			atendimentosFiltrados() {
				if (!this.atendimentos) return null;
				if (this.search && this.search.trim()) {
					return this.atendimentos.filter(atendimento =>
						atendimento.Protocolo == this.search.replace("#", "") ||
						atendimento.TermosDeBusca?.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(this.search.trim().normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase())
					).sort((a, b) => a.DataUltimaMensagem > b.DataUltimaMensagem ? -1 : 0);
				}

				let atendimentos = this.filtroSelecionado ? this.filtroSelecionado.getLista().filter(this.filtroSelecionado.seletor) : this.atendimentosAtivos;

				if (this.setorSelecionado) atendimentos = atendimentos.filter(atendimento => atendimento.SetorId == this.setorSelecionado.Id);

				return [
					...this.atendimentosAtivos.filter(item => item.DataAgendamentoExpirado && item.DataAgendamentoExpirado > item.DataUltimaLeitura).sort((a, b) => a.DataAgendamentoExpirado < b.DataAgendamentoExpirado ? -1 : 0),
					...atendimentos.filter(item => !item.DataAgendamentoExpirado || item.DataAgendamentoExpirado <= item.DataUltimaLeitura).sort((a, b) => a.DataUltimaMensagem > b.DataUltimaMensagem ? -1 : 0)
				];
			},
			tabulacoesVisiveis() {
				let tabulacoes = [],
					adicionarTabulacoes = lista => {
						lista.forEach(item => {
							tabulacoes.push(item);
							if (item.Tipo == 3 && this.tabulacoes[item.TabulacaoId]) {
								let itemSelecionado = item.Itens.find(tItem => tItem.ItemId == this.tabulacoes[item.TabulacaoId]);
								if (!itemSelecionado || !itemSelecionado.Exibir || itemSelecionado.Exibir.length == 0) return;
								adicionarTabulacoes(this.tabulacoesBase.filter(item => itemSelecionado.Exibir.includes(item.TabulacaoId)));
							}
						})
					};
				adicionarTabulacoes(this.tabulacoesBase.filter(item => !item.Oculto));
				this.tabulacoesBase.filter(item => !tabulacoes.includes(item)).forEach(item => {
					this.tabulacoes[item.TabulacaoId] = null;
				});
				return tabulacoes;
			},
			quantidadeAtendimentosSimultaneosExcedido() {
				if (this.config?.QuantidadeAtendimentosSimultaneos == null) return false;
				return this.config?.QuantidadeAtendimentosSimultaneos <= this.atendimentosAtivos.length;
			}
		},
		watch: {
			$isOnline() {
				if (this.$isOnline && this.chatHubConnectionStatus == "offline") this.setChatHubConnectionStatus("reconnected", 3000);
				else if (!this.$isOnline) this.setChatHubConnectionStatus("offline");
			},
			"atendimentoSelecionado.ClienteId"(clienteId) {
				if (!clienteId) {
					this.cliente = new Cliente();
					this.historicoAtendimentos = null;
					return;
				}
				this.cliente = null;
				this.consultarClienteDoAtendimento();
				this.consultarHistoricoAtendimento();
			},
			filaAtiva() {
				let usersConfig = JSON.parse(localStorage.usersConfig ?? "{}");
				if (!usersConfig[this.meuId]) usersConfig[this.meuId] = {};
				usersConfig[this.meuId].filaAtiva = this.filaAtiva;
				localStorage.usersConfig = JSON.stringify(usersConfig);
			}
		},
		methods: {
			toggleFilaAtiva() {
				if (this.chatHubConnection) this.chatHubConnection[`${!this.filaAtiva ? 'liberar' : 'paralisar'}FilaDeAtendimento`]();
			},
			toggleDisponibilidadeDoCanal(usuarioSetor, habilitar) {
				axios.put(`api/usuariosetor/${usuarioSetor.Id}/${habilitar ? "habilitar" : "desabilitar"}`)
					.then(() => {
						this.toastSucesso({ mensagem: `O canal foi ${habilitar ? "habilitado" : "desabilitado"} com sucesso!`, titulo: "Concluído" });
					})
					.catch(error => {
						if (error.response?.status == 400) {
							this.toastErro({ mensagem: "Você não tem mais permissão para alterar a disponibilidade do canal." });
							usuarioSetor.Habilitado = !habilitar;
							this.config.HabilitarEscolhaDeCanal = false;
							return;
						}
						this.toastErro({ mensagem: "Não foi possível alterar a disponibilidade do canal no momento." });
					});
			},
			BuscarConfiguracoes() {
				axios.get(`api/v2/clientes/BuscarConfiguracoes`).then(response => {
					response.data.forEach(comp => {
						this.camposComplementares.push({
							ClientesComplementoOpcao: comp.ClientesComplementoOpcao,
							NomeCampo: comp.NomeCampo,
							TipoCampo: comp.TipoCampo,
							ClientesConfigComplementoId: comp.Id,
							Resposta: this.cliente.Id ? this.buscaComp.find(c => c.ClientesConfigComplementoId === comp.Id)?.Resposta ?? null : null,
							RespostaOpcaoId: this.cliente.Id ? this.buscaComp.find(c => c.ClientesConfigComplementoId === comp.Id)?.RespostaOpcaoId ?? null : null,
							Id: this.cliente.Id ? this.buscaComp.find(c => c.ClientesConfigComplementoId === comp.Id)?.Id ?? null : null
						});
					});
				})

			},
			selecionarAtendimento(atendimento) {
				if (this.atendimentoSelecionado == atendimento) return;
				this.atendimentoSelecionado = atendimento;
				if (this.cliente && this.cliente.Id == this.atendimentoSelecionado.ClienteId && this.atendimentoSelecionado.Canal?.Tipo == "E-mail") this.atendimentoSelecionado.Editor.Para = this.cliente.Email;
				this.$nextTick(() => {
					this.$refs["chat"].scrollToNewMessages("instant");
				});
				this.consultarTabulacoesDoAtendimento();
				this.templatesMensagem = null;
				axios.get(`api/templates-mensagem/templates-do-atendimento/${atendimento.Id}`).then(response => {
					if (!response.data) return;
					this.templatesMensagem = response.data.map(item => {
						Object.defineProperty(item, "TemplateDoCliente", {
							get: () => {
								let template = item.Template.replaceAll("{CODIGO_ATENDIMENTO}", this.atendimentoSelecionado.Protocolo);
								if (!this.cliente) return template;
								if (this.cliente.RazaoSocial?.trim()) template = template.replaceAll("{CLIENTE_NOME}", this.cliente.RazaoSocial);
								if (this.cliente.Email?.trim()) template = template.replaceAll("{CLIENTE_EMAIL}", this.cliente.Email);
								if (this.cliente.Telefone?.trim()) template = template.replaceAll("{CLIENTE_TELEFONE}", this.cliente.Telefone);
								if (this.cliente.CnpjCpf?.trim()) template = template.replaceAll("{CLIENTE_CNPJ_CPF}", this.cliente.CnpjCpf);
								return template;
							}
						});
						return item;
					});
				});
			},
			abrirModalConversaPadrao(atendimento) {
				this.modalData["conversa-padrao"] = {
					Id: atendimento.Id,
					Protocolo: atendimento.Protocolo,
					ClienteId: this.atendimentoSelecionado.ClienteId
				};
				this.$refs["conversa-padrao"].show();
			},
			consultarTabulacoesDoAtendimento() {
				Object.keys(this.tabulacoes).forEach(key => this.tabulacoes[key] = null);
				return axios.get(`api/atendimento/${this.atendimentoSelecionado.Id}/tabulacoes`).then(response => {
					this.tabulacoesSalvas = response.data ?? {};
					Object.keys(this.tabulacoesSalvas).forEach(key => this.tabulacoes[key] = response.data[key]);
					return this.tabulacoes;
				});
			},
			consultarClienteDoAtendimento() {
				axios.get(`api/cliente/cliente-do-atendimento/${this.atendimentoSelecionado.Id}`)
					.then(response => {
						this.cliente = new Cliente(response.data.Cliente);
						if (this.atendimentoSelecionado && this.atendimentoSelecionado.Canal?.Tipo == "E-mail") {
							this.atendimentoSelecionado.Editor.Para = this.cliente.Email;
						}
						this.buscaComp = response.data.Complementares;
						this.camposComplementares.forEach(campoComp => {
							campoComp.Resposta = this.cliente.Id ? this.buscaComp.find(c => c.ClientesConfigComplementoId === campoComp.ClientesConfigComplementoId)?.Resposta ?? null : null;
							campoComp.RespostaOpcaoId = this.cliente.Id ? this.buscaComp.find(c => c.ClientesConfigComplementoId === campoComp.ClientesConfigComplementoId)?.RespostaOpcaoId ?? null : null;
							campoComp.Id = this.cliente.Id ? this.buscaComp.find(c => c.ClientesConfigComplementoId === campoComp.ClientesConfigComplementoId)?.Id ?? null : null;
						});
						return this.cliente;
					})
					.catch(error => {
						// Trate o erro de acordo com a necessidade
						console.error('Erro na requisição:', error);
					});
			},
			consultarHistoricoAtendimento() {
				this.historicoAtendimentos = null;
				if (!this.atendimentoSelecionado?.ClienteId) return;
				this.loaders.consultandoHistoricoAtendimentos = true;
				axios.get(`api/cliente/${this.atendimentoSelecionado.ClienteId}/historico-atendimentos`).then(response => {
					this.loaders.consultandoHistoricoAtendimentos = false;
					if (!response || !response.data) return;
					this.historicoAtendimentos = response.data.filter(atendimento => atendimento.Id != this.atendimentoSelecionado.Id);
				}).catch(() => this.loaders.consultandoHistoricoAtendimentos = false);
			},
			criarAtendimento() {
				let { setorId, canalId } = this.modalData["criar-atendimento"];
				this.loaders.criandoAtendimento = true;
				axios.post(`api/atendimento/atendimento-ativo`, {
					SetorId: setorId,
					CanalId: canalId
				}).then(response => {
					this.loaders.criandoAtendimento = false;
					this.$bvModal.hide("criar-atendimento");
					this.toastSucesso({ mensagem: `Atendimento ativo criado com sucesso sob o protocolo #${response.data.Protocolo}!`, titulo: "Concluído" });
					this.modalData["criar-atendimento"].setorId = null;
					this.modalData["criar-atendimento"].canalId = null;
				}).catch(() => {
					this.loaders.criandoAtendimento = false;
					this.toastErro({ mensagem: "Não foi possível criar o atendimento no momento." });
				});
			},
			alterarCanalAtendimento() {
				let { setorId, canalId } = this.modalData["alterar-canal"];
				this.loaders.alterandoCanal = true;
				axios.put(`api/atendimento/${this.atendimentoSelecionado.Id}/alterar-canal`, {
					SetorId: setorId,
					CanalId: canalId
				}).then(() => {
					this.loaders.alterandoCanal = false;
					this.$bvModal.hide("alterar-canal");
					this.toastSucesso({ mensagem: `Alteração efetuada com sucesso!`, titulo: "Concluído" });
					this.modalData["alterar-canal"].setorId = null;
					this.modalData["alterar-canal"].canalId = null;
					this.atendimentoSelecionado.refresh();
				}).catch(() => {
					this.loaders.alterandoCanal = false;
					this.toastErro({ mensagem: "Não foi possível alterar o canal deste atendimento no momento." });
				});
			},
			iniciarTransferenciaDoAtendimento() {
				// Verificação de campos obrigatórios para transferência antes de iniciar a transferência de fato.
				let tabulacoesObrigatoriasTransferencia = this.tabulacoesVisiveis.filter(item => item.ObrigatorioTransferencia && !this.tabulacoesSalvas[item.TabulacaoId]);
				if (tabulacoesObrigatoriasTransferencia.length > 0) {
					let tabulacoesTitulos = tabulacoesObrigatoriasTransferencia.map(tabulacao => tabulacao.Titulo),
						aviso = tabulacoesObrigatoriasTransferencia.length > 1 ? `As seguintes tabulações são obrigatórias para transferência:<br /><br /><ul>${tabulacoesTitulos.map(tabulacao => `<li><b>${tabulacao}</b></li>`).join("")}</ul>` : `A tabulação <b>${tabulacoesTitulos[0]}</b> é obrigatória para transferência.`;
					this.$bvModal.msgBoxOk([this.$createElement("div", { domProps: { innerHTML: aviso } })], {
						title: "Antes de transferir...",
						size: "md",
						buttonSize: "md",
						okVariant: "success",
						okTitle: "Entendi"
					});
					return;
				}

				this.setoresEUsuarios = null;
				this.$bvModal.show("transferir-atendimento");
				this.consultarSetoresEUsuarios().then(() => {
					this.modalData["transferir-atendimento"].setor = this.setoresEUsuarios.find(setor => setor.Id == this.atendimentoSelecionado.SetorId);
				});
			},
			async transferirAtendimento() {
				let { setor, usuario } = this.modalData["transferir-atendimento"];
				let mensagemConfirmacao = null;
				if (usuario && !usuario.ConectadoNoChat) mensagemConfirmacao = "O atendente selecionado está desconectado.";
				else if (setor.Usuarios.filter(usuario => usuario.ConectadoNoChat).length == 0) mensagemConfirmacao = "O setor selecionado não tem nenhum usuário conectado.";
				if (mensagemConfirmacao) {
					let confirm = await this.$bvModal.msgBoxConfirm(`${mensagemConfirmacao} Deseja transferir o atendimento mesmo assim?`, {
						title: "Confirmação",
						size: "md",
						buttonSize: "md",
						okVariant: "danger",
					});
					if (!confirm) return;
				}
				this.loaders.transferindoAtendimento = true;
				axios.put(`api/atendimento/${this.atendimentoSelecionado.Id}/transferir`, {
					SetorId: setor.Id,
					UsuarioId: usuario?.Id
				}).then(() => {
					this.loaders.transferindoAtendimento = false;
					this.atendimentoSelecionado = null;
					this.$bvModal.hide("transferir-atendimento");
					this.toastSucesso({ mensagem: `Transferência efetuada com sucesso!`, titulo: "Concluído" });
					this.modalData["transferir-atendimento"].setor = null;
					this.modalData["transferir-atendimento"].usuario = null;
				}).catch(() => {
					this.loaders.transferindoAtendimento = false;
					this.toastErro({ mensagem: "Não foi possível transferir este atendimento no momento." });
				});
			},
			agendarAtendimento() {
				this.loaders.agendandoAtendimento = true;
				axios.put(`api/atendimento/${this.atendimentoSelecionado.Id}/agendar`, {
					DataAgendamento: this.modalData["agendar-atendimento"].dataAgendamento
				}).then(() => {
					this.loaders.agendandoAtendimento = false;
					this.$bvModal.hide("agendar-atendimento");
					this.toastSucesso({ mensagem: `Atendimento agendado com sucesso!`, titulo: "Concluído" });
					this.atendimentoSelecionado.Status = 7;
					this.atendimentoSelecionado.DataAgendamento = moment(this.modalData["agendar-atendimento"].dataAgendamento);
					this.atendimentoSelecionado = null;
				}).catch(() => {
					this.loaders.agendandoAtendimento = false;
					this.toastErro({ mensagem: "Não foi possível agendar o atendimento no momento." });
				});
			},
			suspenderAtendimento() {
				this.$bvModal.msgBoxConfirm("Tem certeza de que deseja suspender o atendimento?", {
					title: "Confirmação",
					size: "md",
					buttonSize: "md",
					okVariant: "danger",
				}).then(confirm => {
					if (!confirm) return;
					let atendimentoSuspenso = this.atendimentoSelecionado;
					atendimentoSuspenso.EmProcessamento = true;
					axios.put(`api/atendimento/${atendimentoSuspenso.Id}/suspender`).then(() => {
						atendimentoSuspenso.Status = 6;
						if (this.atendimentoSelecionado?.Id == atendimentoSuspenso.Id) this.atendimentoSelecionado = null;
					}).catch(() => {
						atendimentoSuspenso.EmProcessamento = false;
						this.toastErro({ mensagem: "Não foi possível suspender o atendimento no momento." });
					});
				});
			},
			finalizarAtendimento() {
				// Verificação de campos obrigatórios para finalização antes de finalizar de fato.
				let tabulacoesObrigatoriasFinalizacao = this.tabulacoesVisiveis.filter(item => item.ObrigatorioFinalizacao && !this.tabulacoesSalvas[item.TabulacaoId]);
				if (tabulacoesObrigatoriasFinalizacao.length > 0) {
					let tabulacoesTitulos = tabulacoesObrigatoriasFinalizacao.map(tabulacao => tabulacao.Titulo),
						aviso = tabulacoesObrigatoriasFinalizacao.length > 1 ? `As seguintes tabulações são obrigatórias para finalização:<br /><br /><ul>${tabulacoesTitulos.map(tabulacao => `<li><b>${tabulacao}</b></li>`).join("")}</ul>` : `A tabulação <b>${tabulacoesTitulos[0]}</b> é obrigatória para finalização.`;
					this.$bvModal.msgBoxOk([this.$createElement("div", { domProps: { innerHTML: aviso } })], {
						title: "Antes de finalizar...",
						size: "md",
						buttonSize: "md",
						okVariant: "success",
						okTitle: "Entendi"
					});
					return;
				}

				this.$bvModal.msgBoxConfirm("Tem certeza de que deseja finalizar o atendimento?", {
					title: "Confirmação",
					size: "md",
					buttonSize: "md",
					okVariant: "danger",
				}).then(confirm => {
					if (!confirm) return;
					let atendimentoFinalizado = this.atendimentoSelecionado;
					atendimentoFinalizado.EmProcessamento = true;
					axios.put(`api/atendimento/${atendimentoFinalizado.Id}/finalizar`).then(() => {
						atendimentoFinalizado.Status = 2;
						let atendimentoIndex = this.atendimentos.indexOf(atendimentoFinalizado);
						if (this.atendimentoSelecionado?.Id == atendimentoFinalizado.Id) this.atendimentoSelecionado = null;
						this.atendimentos.splice(atendimentoIndex, 1);
					}).catch(() => {
						atendimentoFinalizado.EmProcessamento = false;
						this.toastErro({ mensagem: "Não foi possível finalizar o atendimento no momento." });
					});
				});
			},
			descadastrarDeCampanhas() {
				this.$bvModal.msgBoxConfirm(`Tem certeza de que deseja efetuar o descadastramento do cliente (OPT-OUT) para futuras campanhas?`, {
					title: "Confirmação",
					size: "md",
					buttonSize: "md",
					okVariant: "danger",
				}).then(confirm => {
					if (!confirm) return;
					this.cliente.DescadastradoDeCampanhas = true;
					axios.put(`api/cliente/${this.cliente.Id}/opt-out`).then(() => {
						this.toastSucesso({ mensagem: "Cliente descadastrado com sucesso." });
					}).catch(() => {
						this.cliente.DescadastradoDeCampanhas = false;
						this.toastErro({ mensagem: "Não foi possível descadastrar o cliente para futuras campanhas no momento." });
					});
				});
			},
			salvarTabulacoesAtendimento() {
				if (!this.atendimentoSelecionado || !this.tabulacoes) return;
				let tabulacoes = Object.assign({}, ...Object.keys(this.tabulacoes).filter(key => this.tabulacoes[key] != null && this.tabulacoes[key].toString().trim()).map(key => ({ [key]: this.tabulacoes[key] })));
				this.loaders.salvandoTabulacao = true;
				axios.put(`api/atendimento/${this.atendimentoSelecionado.Id}/tabulacoes`, tabulacoes).then(() => {
					this.loaders.salvandoTabulacao = false;
					this.tabulacoesSalvas = tabulacoes;
					// Necessário por causa da sincronização das tabulações com o cliente
					this.consultarClienteDoAtendimento();
					this.toastSucesso({ mensagem: "Alterações salvas com sucesso." });
				}).catch(() => {
					this.loaders.salvandoTabulacao = false;
					this.toastErro({ mensagem: "Não foi possível salvar as tabulações no momento" });
				});
			},
			salvarClienteAtendimento() {
				if (!this.cliente) return;
				this.loaders.salvandoCliente = true;
				let clienteDados = {
					Cliente: this.cliente.$data,
					Complementares: this.camposComplementares
				}
				let method = this.cliente.Id ? "put" : "post";
				axios[method](`api/cliente/cliente-do-atendimento/${this.atendimentoSelecionado.Id}`, clienteDados).then(() => {
					this.loaders.salvandoCliente = false;
					// Necessário por causa da sincronização do cliente com as tabulações
					this.consultarTabulacoesDoAtendimento();
					this.atendimentoSelecionado.Editor.Para = this.cliente.Email;
					this.toastSucesso({ mensagem: method == "post" ? "Cliente cadastrado e vinculado ao atendimento com sucesso." : "Alterações salvas com sucesso." });
				}).catch(error => {
					this.loaders.salvandoCliente = false;
					if (error.response?.status == 409) {
						this.toastErro({ mensagem: error.response.data });
						return;
					}
					this.toastErro({ mensagem: `Não foi possível ${this.cliente.Id ? "salvar alterações do" : "cadastrar o"} cliente no momento.` });
				});
			},
			async removerVinculoClienteAtendimento() {
				let confirm = await this.$bvModal.msgBoxConfirm(`O cliente será desvinculado deste atendimento. Deseja continuar?`, {
					title: "Confirmação",
					size: "md",
					buttonSize: "md",
					okVariant: "danger",
				});
				if (!confirm) return;
				this.loaders.vinculandoCliente = false;
				axios.put(`api/atendimento/${this.atendimentoSelecionado.Id}/desvincular-cliente`).then(() => {
					this.loaders.vinculandoCliente = false;
					this.atendimentoSelecionado.ClienteId = null;
					this.atendimentoSelecionado.Editor.Para = null;
					this.toastSucesso({ mensagem: `Cliente desvinculado com sucesso!`, titulo: "Concluído" });
				}).catch(() => {
					this.loaders.vinculandoCliente = false;
					this.toastErro({ mensagem: "Não foi possível desvincular o cliente do atendimento." });
				});
			},
			vincularClienteAtendimento() {
				this.$refs["selecao-cliente"].selecionarCliente().then(clienteId => {
					if (!clienteId) return;
					this.loaders.vinculandoCliente = true;
					let form = new FormData();
					form.append("clienteId", clienteId);
					axios.put(`api/atendimento/${this.atendimentoSelecionado.Id}/vincular-cliente`, form).then(() => {
						this.loaders.vinculandoCliente = false;
						this.toastSucesso({ mensagem: "Cliente vinculado ao atendimento com sucesso!", titulo: "Concluído" });
						this.consultarTabulacoesDoAtendimento();
					}).catch(error => {
						this.loaders.vinculandoCliente = false;
						if (error.response?.status == 409 && error.response?.data) {
							this.$bvModal.msgBoxOk(error.response?.data, {
								title: "Vínculo rejeitado pelo sistema",
								centered: true,
								size: "md",
								buttonSize: "md",
								okVariant: "danger",
								okTitle: "Entendi"
							})
							return;
						}
						this.toastErro({ mensagem: "Não foi possível vincular o cliente ao atendimento." });
					});
				});
			},
			toggleOptionsList(list) {
				this.resetOptionsList();
				this.optionsListControl[list] = !this.optionsListControl[list];
			},
			resetOptionsList() {
				Object.keys(this.optionsListControl).forEach(item => {
					this.optionsListControl[item] = false;
				});
			},
			toggleFullscreen() {
				if (!document.fullscreenElement) {
					this.$refs["atendimento-screen"].requestFullscreen();
					return;
				}
				document.exitFullscreen();
			},
			resetWindowSize() {
				this.windowSizeControl.size = window.innerWidth;
				let { size, minSize, mode } = this.windowSizeControl;
				this.windowSizeControl.mode = size < minSize ? "slim" : "normal";
				if (size < minSize && mode == "normal") this.isDataOpen = false;
				if (size >= minSize && mode == "slim") this.isDataOpen = true;
			},
			toastErro(dados) {
				let { mensagem, titulo, tempo } = dados;
				this.$bvToast.toast(mensagem ?? "Não foi possível concluir sua solicitação.", {
					title: titulo ?? "Ops, algo deu errado...",
					toaster: "b-toaster-top-right",
					variant: "danger",
					solid: true,
					autoHideDelay: tempo ?? 5000
				});
			},
			toastSucesso(dados) {
				let { mensagem, titulo, tempo } = dados;
				this.$bvToast.toast(mensagem ?? "Sua solicitação foi concluída com sucesso", {
					title: titulo ?? "Sucesso",
					toaster: "b-toaster-top-right",
					variant: "success",
					solid: true,
					autoHideDelay: tempo ?? 5000
				});
			},
			async receberAtendimento(atendimentoId) {
				if (!this.atendimentos) return;
				let atendimento = this.atendimentos.find(item => item.Id == atendimentoId);
				if (!atendimento) {
					atendimento = new Atendimento({ Id: atendimentoId });
					return await atendimento.refresh().then(() => {
						if (!this.atendimentos.find(item => item.Id == atendimentoId)) this.atendimentos.push(atendimento);
					});
				}
				return await atendimento.refresh().then(() => {
					if (atendimento.UsuarioId != this.meuId) this.atendimentos.splice(this.atendimentos.indexOf(atendimento), 1);
				});
			},
			consultarSetoresEUsuarios() {
				this.loaders.consultandoSetoresEUsuarios = true;
				return axios.get(`api/canal/${this.atendimentoSelecionado.CanalId}/setores-e-usuarios`).then(response => {
					this.setoresEUsuarios = response.data;
					this.loaders.consultandoSetoresEUsuarios = false;
					return this.setoresEUsuarios;
				});
			},
			setChatHubConnectionStatus(status, autoHideTimeOut) {
				clearTimeout(this.chatHubConnectionStatusHideTimeout);
				this.chatHubConnectionStatus = status;
				if (autoHideTimeOut != undefined) {
					this.chatHubConnectionStatusHideTimeout = setTimeout(() => {
						this.chatHubConnectionStatus = null;
					}, autoHideTimeOut);
				}
			},
			setControleTelefonia(controle, autoHideTimeOut) {
				clearTimeout(this.controleTelefoniaHideTimeout);
				this.controleTelefonia = controle;
				if (autoHideTimeOut != undefined) {
					this.controleTelefoniaHideTimeout = setTimeout(() => {
						this.controleTelefonia = null;
					}, autoHideTimeOut);
				}
			},
			tryStopRingtone() {
				if (!this.controleTelefonia?.ringtone) return;
				this.controleTelefonia.ringtone.pause();
				this.controleTelefonia.ringtone.currentTime = 0;
			},
			makeCall() {
				if (!this.atendimentoSelecionado) return;
				this.chatHubConnection.makeCall(this.atendimentoSelecionado?.Id);
				let ringback = new Audio(`${omniHost}/audio/call-ringback.mp3`);
				ringback.loop = true;
				this.setControleTelefonia({
					type: "ChamadaAtivaSolicitada",
					nome: this.atendimentoSelecionado.Cliente,
					telefone: this.atendimentoSelecionado.ClienteTelefone,
					protocolo: this.atendimentoSelecionado.Protocolo,
					ringtone: ringback,
					cancelCall: () => {
						this.chatHubConnection.cancelCall();
					}
				});
				ringback.play();
			},
			reload() {
				this.setChatHubConnectionStatus("connecting");
				window.location.reload();
			},
			maskPhone(phoneNumber) {
				if (!phoneNumber || phoneNumber.length < 10) return phoneNumber;
				phoneNumber = phoneNumber.replace(/\D/g, "");
				if (phoneNumber.length > 10 && phoneNumber.startsWith("55")) phoneNumber = phoneNumber.substring(2);
				return phoneNumber.replace(/(\d{2})(\d{4,5})(\d{4})$/, "($1) $2-$3");
			}
		},
		mounted() {
			this.moment = moment;

			ControleDeEventos: {
				document.onfullscreenchange = () => this.isFullscreen = document.fullscreenElement;
				document.onkeyup = ({ key }) => {
					if (key == "Escape") this.atendimentoSelecionado = null;
				};
				document.onclick = () => {
					this.resetOptionsList();
				};
				window.onresize = this.resetWindowSize;
				this.resetWindowSize();
			}

			if (localStorage.usersConfig) this.filaAtiva = JSON.parse(localStorage.usersConfig)[this.meuId]?.filaAtiva ?? true;

			axios.get("api/atendimento/config").then(response => {
				this.config = response.data;
				this.BuscarConfiguracoes();
			});
			Atendimento.getAtendimentos().then(atendimentos => {
				this.atendimentos = atendimentos;
			});
			axios.get("api/usuario/meus-setores-e-canais").then(response => {
				this.setoresECanais = response.data;
			});
			axios.get("api/tabulacao/BuscaTabulacoes").then(response => {
				this.tabulacoesBase = response.data;
				this.tabulacoes = {};
				this.tabulacoesBase.forEach(item => this.$set(this.tabulacoes, item.TabulacaoId, null));
			});
			this.setChatHubConnectionStatus("connecting");
			ChatHub.connect().then(connection => {
				this.setChatHubConnectionStatus("connected", 3000);
				this.chatHubConnection = connection;
				if (this.filaAtiva) this.chatHubConnection.liberarFilaDeAtendimento();
				this.chatHubConnection.subscribe("Atendimento", atendimentoId => {
					this.receberAtendimento(atendimentoId).then(() => {
						if (this.controleTelefonia && this.controleTelefonia.type == "ChamadaIniciada" && this.controleTelefonia.atendimentoId == atendimentoId && !this.controleTelefonia.atendimentoSelecionado) {
							let atendimento = this.atendimentos.find(atendimento => atendimento.Id == atendimentoId);
							if (atendimento) {
								this.controleTelefonia.atendimentoSelecionado = true;
								this.selecionarAtendimento(atendimento);
							}
						}
					});

				});
				this.chatHubConnection.subscribe("ChamadaSolicitada", (telefone, nome) => {
					this.tryStopRingtone();
					let ringtone = new Audio(`${omniHost}/audio/call-request.mp3`);
					ringtone.loop = true;
					this.setControleTelefonia({
						type: "ChamadaSolicitada",
						nome: nome,
						telefone: telefone,
						ringtone: ringtone,
						answerCall: this.chatHubConnection.answerCall,
						rejectCall: () => {
							this.chatHubConnection.rejectCall();
							this.tryStopRingtone();
							this.setControleTelefonia({ type: "ChamadaFinalizada" });
						}
					});
					ringtone.play();
				});
				this.chatHubConnection.subscribe("ChamadaFinalizada", () => {
					this.tryStopRingtone();
					this.chatHubConnection.stopWebRTC();
					this.setControleTelefonia({ type: "ChamadaFinalizada" }, 3000);
				});
				this.chatHubConnection.subscribe("ChamadaIniciada", (atendimentoId, protocolo, telefone, nome) => {
					this.tryStopRingtone();
					let startedAudio = new Audio(`${omniHost}/audio/call-started.mp3`);
					startedAudio.loop = false;
					startedAudio.play();
					this.chatHubConnection.startWebRTC(atendimentoId, true);
					this.setControleTelefonia({
						type: "ChamadaIniciada",
						atendimentoId,
						protocolo,
						telefone,
						nome,
						timeElapsed: 0,
						timer: "00:00",
						micEnabled: true,
						toggleMic: () => {
							this.controleTelefonia.micEnabled = !this.controleTelefonia.micEnabled;
							this.chatHubConnection.mediaDevices.getAudioTracks()[0].enabled = this.controleTelefonia.micEnabled;

							if (this.controleTelefonia.micEnabled) this.chatHubConnection.takeOffHoldCall();
							else this.chatHubConnection.putOnHoldCall();
						},
						hangupCall: this.chatHubConnection.hangupCall,
						atendimentoSelecionado: false
					});
					let timeCounter = () => {
						if (this.controleTelefonia.type != "ChamadaIniciada") return;
						let minutes = Math.floor(this.controleTelefonia.timeElapsed / 60);
						let seconds = this.controleTelefonia.timeElapsed - (minutes * 60);
						this.controleTelefonia.timer = `${minutes < 10 ? "0" : ""}${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
						this.controleTelefonia.timeElapsed++;
						setTimeout(timeCounter, 1000);
					};
					timeCounter();
					let atendimento = this.atendimentos.find(item => item.atendimentoId == atendimentoId);
					if (atendimento && !this.controleTelefonia?.atendimentoSelecionado) this.selecionarAtendimento(atendimento);
				});
				this.chatHubConnection.subscribe("ChamadaRejeitada", () => {
					this.tryStopRingtone();
					this.setControleTelefonia({ type: "ChamadaRejeitada" }, 3000);
				});
				this.chatHubConnection.subscribe("ChamadaInvalida", () => {
					this.tryStopRingtone();
					this.setControleTelefonia({ type: "ChamadaInvalida" }, 3000);
				});
				this.chatHubConnection.subscribe("FilaLiberada", () => {
					this.filaAtiva = true;
					this.chatHubConnection.startPhone();
				});
				this.chatHubConnection.subscribe("FilaParalisada", () => this.filaAtiva = false);
				this.chatHubConnection.onReconnecting(() => {
					this.setChatHubConnectionStatus("reconnecting");
				});
				this.chatHubConnection.onReconnected(() => {
					this.setChatHubConnectionStatus("reconnected", 3000);
					if (this.filaAtiva) this.chatHubConnection.liberarFilaDeAtendimento();
				});
				this.chatHubConnection.onClose(() => {
					this.setChatHubConnectionStatus("disconnected");
				});
			});
		},
		destroyed() {
			document.onfullscreenchange = null;
			document.onkeyup = null;
			document.onclick = null;
			window.onresize = null;
			this.chatHubConnection.stopWebRTC();
			this.chatHubConnection.disconnect();
			this.tryStopRingtone();
		}
	};
</script>

<style scoped>
	::placeholder {
		color: var(--cinza-4);
		transition: all ease-in-out .3s;
	}

	.conversa_funil {
		cursor: pointer;
		color: #878787;
		font-size: 1.45rem;
		margin-right: 0.4vw;
	}

	.span-link {
		text-decoration: underline;
		cursor: pointer;
	}

	.text-cliente {
		color: var(--cor-primaria-cliente);
	}

	.atendimento-box {
		background-color: var(--cinza-1);
		margin: 5px 7px;
	}

		.atendimento-box.h-100 {
			height: calc(100% - 10px) !important;
		}

	.protocolos-filter-box {
		width: calc(100% - 10px);
		cursor: pointer;
	}

	.list-item {
		background-color: var(--cinza-3);
		margin: 5px;
	}

		.list-item .protocolo-canal {
			font-size: 40px;
		}

		.list-item .protocolo-numero {
			font-size: 16px;
			font-weight: bold;
			line-height: 18px;
		}

		.list-item .protocolo-cliente {
			font-size: 16px;
			line-height: 18px;
		}

		.list-item .protocolo-data {
			font-size: 10px;
			line-height: 12px;
		}

		.list-item .protocolo-novas_mensagens {
			width: 36px;
			height: 36px;
			padding: 7px 0;
			background-color: #dc5330;
			text-align: center;
			color: #fff;
			border-radius: 50%;
		}

		.list-item.closing {
			opacity: .5;
			cursor: default !important;
		}

	.atendimento-box-header {
		width: calc(100% - 30px);
		height: 50px;
		border-bottom: 1px var(--cinza-4) solid;
		margin: 0 15px;
		padding: 7px 10px;
		line-height: 16px;
	}

		.atendimento-box-header .screen-action {
			font-size: 23px;
			cursor: pointer;
			margin: 6px 0 6px 10px;
			animation: fade-in-out .5s;
		}

	.chat-container {
		height: calc(100% - 65px);
		margin: 5px 15px;
		overflow: hidden;
	}

	ul.options-list {
		position: absolute;
		list-style-type: none;
		padding: 0;
		background-color: #fff;
		box-shadow: 1px 1px 5px rgba(0, 0, 0, .25);
		z-index: 1000;
		animation: fade-in-out .5s;
		user-select: none;
	}

		ul.options-list > li {
			display: table;
			width: 100%;
			padding: 10px 15px;
			position: relative;
			white-space: nowrap;
		}

			ul.options-list > li:hover {
				background-color: rgba(0, 0, 0, .03);
			}

				ul.options-list > li:hover > ul {
					display: block;
				}

			ul.options-list > li.active {
				font-weight: bold;
			}

			ul.options-list > li i.fas, ul.options-list > li i.fa {
				font-size: 14px;
			}

			ul.options-list > li > ul {
				display: none;
				left: 100%;
				top: 0;
			}

	#atendimento-screen {
		font-size: 14px;
		width: calc(100% - 20px);
		height: calc(100vh - 76px);
		min-width: 1050px;
		margin: 10px;
		padding: 7px 10px;
		background-color: var(--cinza-3);
		color: var(--cinza-5);
		cursor: default;
		overflow: hidden;
	}

	#protocolos {
		width: 380px;
		height: 100%;
		float: left;
		animation: fade-in-out .5s;
	}

	#protocolos-search {
		height: 50px;
		padding: 7px 10px;
	}

		#protocolos-search > div {
			height: 100%;
		}

		#protocolos-search i.fas {
			font-size: 25px;
			cursor: pointer;
		}

	#protocolos-search-input-box {
		float: left;
		width: calc(100% - 18px);
		border-bottom: 1px var(--cinza-4) solid;
		transition: all ease-in-out .3s;
	}

		#protocolos-search-input-box.searching {
			border-bottom-color: var(--cor-primaria-cliente);
		}

			#protocolos-search-input-box.searching ::placeholder,
			#protocolos-search-input-box.searching > i.fas {
				color: var(--cor-primaria-cliente);
			}

		#protocolos-search-input-box > input[type=text] {
			width: calc(100% - 30px);
			margin: 0 2px;
			background-color: transparent;
			color: var(--cor-primaria-cliente);
			height: 100%;
			border: 0;
			outline-style: none;
			font-size: 16px;
		}

			#protocolos-search-input-box > input[type=text]:focus {
				box-shadow: none;
			}

		#protocolos-search-input-box > i.fas {
			font-size: 20px;
			transition: all ease-in-out .3s;
		}

	#protocolos-search-options {
		float: right;
		padding: 5px 0;
		position: relative;
	}

		#protocolos-search-options > ul.options-list {
			background-color: #fcf4f4;
			right: -10px;
			width: 220px;
			margin-top: 10px;
		}

			#protocolos-search-options > ul.options-list i.fas, #protocolos-search-options > ul.options-list i.fa {
				font-size: 14px;
				line-height: 20px;
			}

	#protocolos-queue-filter {
		width: calc(100% - 14px);
		height: 37px;
		box-sizing: border-box;
		cursor: pointer;
		position: relative;
		transition: all ease-in-out .3s;
	}

		#protocolos-queue-filter:hover {
			background-color: var(--cinza-2);
		}

		#protocolos-queue-filter > ul.options-list {
			width: 100%;
			top: 0;
			background-color: #fcf4f4;
		}

			#protocolos-queue-filter > ul.options-list > li {
				padding: 5px 10px;
			}

				#protocolos-queue-filter > ul.options-list > li > div.float-left {
					max-width: 60%;
				}

	#protocolos-list {
		display: flex;
		flex-direction: column;
		height: calc(100% - 107px);
		overflow-y: hidden;
		transition: all ease-in-out .3s;
	}

		#protocolos-list > .list-item {
			height: 37px;
			transition: all ease-in-out .3s;
		}

		#protocolos-list > .protocolos-filter-box {
			background-color: var(--cinza-2);
			position: relative;
		}

			#protocolos-list > .protocolos-filter-box:hover {
				background-color: var(--cinza-2);
			}

			#protocolos-list > .protocolos-filter-box > ul.options-list {
				width: 100%;
				top: 0;
				background-color: var(--cinza-2);
			}

				#protocolos-list > .protocolos-filter-box > ul.options-list > li {
					padding: 5px 10px;
				}

					#protocolos-list > .protocolos-filter-box > ul.options-list > li > div.float-left {
						max-width: 60%;
					}

	#protocolos-list-container {
		flex-grow: 1;
		overflow-y: scroll;
		margin: 5px;
		transition: all ease-in-out .3s;
	}

		#protocolos-list-container > .list-item {
			width: calc(100% - 10px);
			border-left: 6px solid;
			margin-top: 0;
			padding: 10px 15px;
			transition: all ease-in-out .3s;
			cursor: pointer;
			animation: slide-up .5s;
		}

			#protocolos-list-container > .list-item:not(.active):hover {
				background-color: var(--cinza-2);
			}

			#protocolos-list-container > .list-item.active {
				background-color: var(--cor-primaria-cliente);
				color: #fff;
				cursor: default;
			}

	#protocolos.on-search #protocolos-queue-filter,
	#protocolos.on-search #protocolos-list > .list-item {
		height: 0;
		overflow: hidden;
		opacity: 0;
		margin: 0;
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}

	#protocolos.on-search #protocolos-list {
		height: calc(100% - 70px);
		padding-top: 5px;
	}

	#protocolos.on-search #protocolos-list-container {
		height: calc(100% - 6px);
	}

	#atendimento {
		width: calc(100% - 380px);
		height: 100%;
		float: left;
		animation: slide-up .5s;
	}

	#atendimento-chat {
		width: 64%;
		height: 100%;
		float: left;
		transition: all ease-in-out .3s;
	}

	#atendimento-box-header-row1 {
		flex-wrap: nowrap;
	}

	#atendimento-box-header-col1 {
		flex: 0 0 42px;
	}

	#atendimento-box-header-col2 {
		flex: 0 0 calc(100% - 154px);
		overflow: hidden;
	}

		#atendimento-box-header-col2 > div {
			max-width: 100%;
		}

	#atendimento-data.closed + #atendimento-chat #atendimento-box-header-col2 {
		flex: 0 0 calc(100% - 182px);
	}

	#atendimento-box-header-col3 {
		flex: 0 0 100px;
		text-align: right;
		margin-right: 12px;
		display: flex;
		align-items: center;
	}

	#atendimento-chat-title {
		font-size: 16px;
		font-weight: bold;
	}

	#atendimento-chat-canal-icon {
		font-size: 30px;
	}

	#atendimento-chat-cliente-nome {
		display: inline-block;
		width: 100%;
		background-color: red;
	}

	#atendimento-chat-canal-nome {
		display: inline-block;
		vertical-align: top;
		max-width: 130px;
	}

	#atendimento-chat-canal-trocar {
		cursor: pointer;
	}

	#atendimento-acoes-opener {
		position: relative;
		display: inline-block;
		width: 23px;
		height: 23px;
		font-size: 12px;
		vertical-align: top;
		margin: 7px 0;
	}

		#atendimento-acoes-opener > i.fas {
			width: 100%;
			height: 100%;
			text-align: center;
			border: 1px solid;
			border-radius: 50%;
			padding: 5px;
			color: var(--cor-primaria-cliente);
			cursor: pointer;
		}

			#atendimento-acoes-opener > i.fas.fa-minus {
				background-color: var(--cor-primaria-cliente);
				color: #fff;
			}

		#atendimento-acoes-opener > ul.options-list {
			width: 200px;
			background-color: var(--cor-primaria-cliente);
			font-family: Avenir, Helvetica, Arial, sans-serif;
			font-weight: bold;
			padding: 5px 0;
			top: 70%;
			left: 45%;
			text-align: left;
			color: #fff;
		}

			#atendimento-acoes-opener > ul.options-list > li {
				padding: 5px 10px;
			}

				#atendimento-acoes-opener > ul.options-list > li:hover {
					background-color: rgba(255, 255, 255, .15);
					font-weight: bold;
				}

				#atendimento-acoes-opener > ul.options-list > li.templates-wpp-busines-selector {
					background-color: #218838;
				}

					#atendimento-acoes-opener > ul.options-list > li.templates-wpp-busines-selector:hover {
						background-color: #25d366;
					}

	#atendimento.slim #atendimento-acoes-opener > ul.options-list,
	#atendimento-data.closed + #atendimento-chat #atendimento-acoes-opener > ul.options-list {
		left: auto;
		right: 40%;
	}

	#atendimento-data {
		width: 36%;
		height: 100%;
		float: right;
		transition: all ease-in-out .3s;
	}

		#atendimento-data.closed {
			width: 0;
			opacity: 0;
			font-size: 0;
			overflow: auto;
		}

			#atendimento-data.closed + #atendimento-chat {
				width: 100%;
			}

	#atendimento.slim > #atendimento-data:not(.closed) {
		width: 100%;
	}

		#atendimento.slim > #atendimento-data:not(.closed) + #atendimento-chat {
			width: 0;
			opacity: 0;
			font-size: 0;
			overflow: auto;
		}

	#atendimento-data-selector {
		position: relative;
		display: table;
		font-size: 16px;
		font-weight: bold;
		cursor: pointer;
	}

		#atendimento-data-selector > i.fas {
			margin-left: 7px;
			font-size: 18px;
		}

		#atendimento-data-selector + ul.options-list {
			width: 220px;
			background-color: var(--cinza-1);
			top: calc(100% + 15px);
			left: -10px;
		}

			#atendimento-data-selector + ul.options-list > li {
				padding: 10px 25px;
			}

	.atendimento-data-container {
		height: calc(100% - 55px);
	}

		.atendimento-data-container > .atendimento-data-body {
			height: calc(100% - 52px);
			margin: 5px 15px;
		}

			.atendimento-data-container > .atendimento-data-body.full-height {
				height: calc(100% - 8px);
			}

			.atendimento-data-container > .atendimento-data-body.app-scroll-custom {
				overflow-y: scroll;
				padding-right: 10px;
			}

		.atendimento-data-container > .atendimento-data-footer {
			height: 38px;
			margin: 0 15px;
		}

	.atendimento-data-footer .atendimento-data-action {
		height: 100%;
		font-size: 12px;
		font-weight: bold;
	}

		.atendimento-data-footer .atendimento-data-action.w-50 {
			width: calc(50% - 5px) !important;
			margin-right: 5px;
		}

			.atendimento-data-footer .atendimento-data-action.w-50 + .w-50 {
				margin-left: 5px;
				margin-right: 0;
			}

	.atendimento-historico {
		padding: 10px;
		background-color: var(--cinza-3);
		margin: 5px 0px;
		cursor: pointer;
		transition: all ease-in-out .3s;
	}

		.atendimento-historico:hover {
			background-color: var(--cinza-2);
		}

		.atendimento-historico i.fas.fa-info-circle {
			font-size: 32px;
		}

		.atendimento-historico .atendimento-historico-small {
			font-size: 10px;
		}

		.atendimento-historico *:not(i.fas) {
			line-height: 14px;
		}

	#loading-spinner {
		display: block;
		width: 30px;
		height: 30px;
		margin: calc(50vh - 91px) auto;
		color: var(--cor-primaria-cliente);
	}

	#connection-status {
		text-align: center;
		font-size: 15px;
		line-height: 15px;
		margin: 0 5px;
		padding: 15px;
		animation: fade-in-out .5s;
		transition: all ease-in-out .5s;
	}

		#connection-status.connecting {
			background-color: var(--cor-primaria-cliente);
			color: #fff;
		}

		#connection-status.connected, #connection-status.reconnected {
			background-color: #27ca44;
			color: #fff;
		}

		#connection-status.reconnecting {
			background-color: #fed95d;
			color: #a53214;
		}

		#connection-status.disconnected {
			background-color: #dc5330;
			color: #fff;
		}

			#connection-status.disconnected .span-link {
				font-size: 11px;
			}

		#connection-status.offline {
			background-color: #dc5330;
			color: #fff;
		}

			#connection-status.offline span.fa-stack {
				height: inherit;
				line-height: inherit;
			}

				#connection-status.offline span.fa-stack > i.fa-times {
					font-size: 8px;
					margin-top: 6px;
					margin-left: 7px;
				}

	#controle-telefonia {
		text-align: center;
		font-size: 15px;
		line-height: 15px;
		margin: 0 5px;
		padding: 15px 5px;
		animation: fade-in-out .5s;
		transition: all ease-in-out .5s;
	}

		#controle-telefonia.ChamadaSolicitada {
			background-color: #27ca44;
			color: #fff;
		}

		#controle-telefonia.ChamadaFinalizada {
			background-color: var(--cor-primaria-cliente);
			color: #fff;
		}

		#controle-telefonia.ChamadaIniciada {
			background-color: #27ca44;
			color: #fff;
		}

			#controle-telefonia.ChamadaIniciada i.fas {
				transition: all ease-in-out .5s;
			}

			#controle-telefonia.ChamadaIniciada i.fa-microphone-slash {
				color: #146d24;
			}

		#controle-telefonia.ChamadaRejeitada {
			background-color: #fed95d;
			color: #a53214;
		}

		#controle-telefonia.ChamadaInvalida {
			background-color: #dc5330;
			color: #fff;
		}

		#controle-telefonia.ChamadaAtivaSolicitada {
			background-color: var(--cor-primaria-cliente);
			color: #fff;
		}

		#controle-telefonia + #connection-status {
			margin-top: 5px;
		}


	#aviso-fila-inativa {
		text-align: center;
		font-size: 15px;
		line-height: 15px;
		margin: 0 5px;
		padding: 15px 5px;
		animation: fade-in-out .5s;
		transition: all ease-in-out .5s;
		background-color: #dc5330;
		color: #fff;
	}

		#aviso-fila-inativa .span-link {
			font-size: 11px;
		}


		#aviso-fila-inativa + #controle-telefonia, #aviso-fila-inativa + #connection-status {
			margin-top: 5px;
		}

	.atendimentos-simultaneos-limit-alert {
		font-size: 12px;
		padding: 3px;
		border-radius: 5px;
		background-color: #fed95d;
		color: #a53214;
	}

	.atendimento-dados-discador {
		max-height: 30vh;
		overflow-y: auto;
		border-bottom: 1px solid var(--cinza-4);
		padding-bottom: 10px;
		margin-bottom: 10px;
	}

		.atendimento-dados-discador table {
			font-size: 12px;
			color: var(--cinza-5);
		}

	@keyframes fade-in-out {
		from {
			opacity: 0;
		}

		to {
			opacity: 1;
		}
	}

	@keyframes slide-up {
		0% {
			margin-top: 30px;
			opacity: 0;
		}

		60% {
			margin-top: 3px;
		}

		100% {
			margin-top: 0;
			opacity: 1;
		}
	}
</style>

<style>
	.chat-editor > div {
		height: 100%;
	}
</style>